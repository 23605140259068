<template>
<div class="vendors">
  <div class="page-header">
    <h1>{{ $t('Inventory Management') }} > {{ $t('Vendors')}} </h1>
  </div>
  <div class="page-content">
    <div class="content-header">
      <h2>{{ $t('Vendors') }}</h2>
      <el-button class="action-button" type="primary" @click="showCreateForm"> {{ $t('Add Vendor') }}</el-button>
    </div>

    <ItemFilter :placeholder="$t('Search by vendor name')" :selection="false" />

    <div class="promotions-list">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">{{ $t('Vendor ID') }}</th>
            <th scope="col">{{ $t('Vendor Name') }}</th>
            <th scope="col">{{ $t('Vendor Address') }}</th>
            <th scope="col">{{ $t('Actions') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>海龍食品供應公司</td>
            <td>金龍工業中心四期5樓12A室</td>
            <td>
              <div class="action-links">
                <a class="action-link" href="#">
                  {{ $t('Edit') }}
                </a>
                <a class="action-link danger" href="#">{{ $t('Delete') }}</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>大發行食品公司</td>
            <td>鴨利洲利興街10號港灣工貿中心1008室</td>
            <td>
              <div class="action-links">
                <a class="action-link" href="#">
                  {{ $t('Edit') }}
                </a>
                <a class="action-link danger" href="#">{{ $t('Delete') }}</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>德州食品供應有限公司</td>
            <td>香港大圍成運路25-27號地舖成全工業大廈</td>
            <td>
              <div class="action-links">
                <a class="action-link" href="#">
                  {{ $t('Edit') }}
                </a>
                <a class="action-link danger" href="#">{{ $t('Delete') }}</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>力生環球有限公司</td>
            <td>香港新界荃灣沙咀道26-38號匯力工業中心11樓06室</td>
            <td>
              <div class="action-links">
                <a class="action-link" href="#">
                  {{ $t('Edit') }}
                </a>
                <a class="action-link danger" href="#">{{ $t('Delete') }}</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>北川國際有限公司</td>
            <td>香港新界荃灣沙咀道40-50號榮豐工業大廈</td>
            <td>
              <div class="action-links">
                <a class="action-link" href="#">
                  {{ $t('Edit') }}
                </a>
                <a class="action-link danger" href="#">{{ $t('Delete') }}</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>九記食品廠</td>
            <td>葵涌打磚砰街16-24號有利工業貨倉大廈7D</td>
            <td>
              <div class="action-links">
                <a class="action-link" href="#">
                  {{ $t('Edit') }}
                </a>
                <a class="action-link danger" href="#">{{ $t('Delete') }}</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>芥和家</td>
            <td>香港新界荃灣沙咀道40-50號榮豐工業大廈902室</td>
            <td>
              <div class="action-links">
                <a class="action-link" href="#">
                  {{ $t('Edit') }}
                </a>
                <a class="action-link danger" href="#">{{ $t('Delete') }}</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td>復興經銷</td>
            <td>金龍工業中心二期1樓1B室</td>
            <td>
              <div class="action-links">
                <a class="action-link" href="#">
                  {{ $t('Edit') }}
                </a>
                <a class="action-link danger" href="#">{{ $t('Delete') }}</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import ItemFilter from '@/components/Filter.vue';
import Common from '@/lib/common';

export default {
name: 'Vendors',
components: {
  ItemFilter,
},
methods: {
  formatDateTime(timestamp, format){
    return Common.formatDateTime(timestamp, format);
  },
},
data(){
  return {
    activeName: 'All',
    displayCreateForm: false,
    displayUpdateForm: false,
    vendors: [],
    orignalVendors: [],
  }
},
computed: mapState({
 apiUrl: state => state.apiUrl,
}),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
</style>
